
import Vue from "vue";

export default Vue.extend({
    name: "DuplicatedEntity",
    props: {
        title: {
            type:String,
            required:true,
        },
        idEntity: {
            type: Number,
            default: undefined,
        },
        options: {
            type:Object,
            default: function() {
				return {};
			}
        },
        checkLineItem:{
            type:Boolean,
            default: false,
        },
        isClone: {
            type:Boolean,
            default: false,
        },
        textAccion: {
            type:String,
            required:true,
        },
    },
    components:{},
    data: () => ({
        data:{
            id: undefined,
            clone:[]
        },
        duplicatedData:[],
        option_oblogatorio: undefined,
        option_dependencies: [],
    }),
    created(){},
    mounted(){
        this.$nextTick(() => {
            this.duplicatedData = Object.values(this.options);
            this.data.id = this.idEntity;
            this.loadReasources();
        });
        
    },
    computed:{
        getOpciones(){
            return this.duplicatedData;
        },
        
    },
    methods:{

        loadReasources(){
            Object.keys(this.options).forEach(o => {
                this.data.clone.push(o);
            });
            if(this.checkLineItem){
                this.option_oblogatorio = this.options.line_item;
                this.option_dependencies.push(this.options.targeting_expression);
                this.option_dependencies.push(this.options.creatives_assignment);
                this.option_dependencies.push(this.options.line_bid_modifiers);
            }
        },

        closeDialog(){
            this.$emit("close");
        },

        verifyOptions(options: any){
            let key = this.getKeyOptions(options);
            return this.data.clone.find(o => o == key);
        },

        removeOption(options: any){
            if(this.verifyOptions(options)){
                if(this.checkLineItem){
                    if(options == this.option_oblogatorio){
                        this.option_dependencies.forEach(o => {
                            if(this.verifyOptions(o)){
                                let key = this.getKeyOptions(o);
                                let index = this.data.clone.findIndex(d => d == key);
                                this.data.clone.splice(index, 1);
                            }
                        });
                        let key = this.getKeyOptions(options);
                        let index = this.data.clone.findIndex(o => o == key);
                        this.data.clone.splice(index, 1);
                    }else{
                        let key = this.getKeyOptions(options);
                        let index = this.data.clone.findIndex(o => o == key);
                        this.data.clone.splice(index, 1);
                    }
                }else{
                    let key = this.getKeyOptions(options);
                    let index = this.data.clone.findIndex(o => o == key);
                    this.data.clone.splice(index, 1);
                }
            }
        },

        getKeyOptions(options: any){
            let key = "";
            for(let i in this.options) {
                if(this.options[i] == options){
                    key = i
                }
            }
            return key;
        },

        insertOption(options: any){
            if(!this.verifyOptions(options)){
                if(this.checkLineItem){
                    if(options != this.option_oblogatorio){
                        if(this.verifyOptions(this.option_oblogatorio)){
                            this.data.clone.push(this.getKeyOptions(options));
                        }
                    }else{
                        this.data.clone.push(this.getKeyOptions(options));
                    }
                }else{
                    this.data.clone.push(this.getKeyOptions(options));
                }
            }       
        },

        async handleDuplicated(){
            this.$emit("handle-duplicated", this.data);
        }
    },
    watch: {}
});
